import React, { useState } from "react"
import { graphql } from "gatsby"

import { Seo as SEO } from "../components/Seo"
import Layout from '../components/layout/Layout'
import { useTranslation } from "react-i18next"
const PageBanner = React.lazy(() => import("../components/common/PageBanner"))
const NewsEventList = React.lazy(() => import("../components/newsAndEvents/NewsEventList"))
const SearchBar = React.lazy(() => import("../components/common/SearchBar"))
const CategoryList = React.lazy(() => import("../components/common/CategoryList"))

const NewsEventPage = ({data, location, pageContext}) => {
    const links = [
        {label: "Home", url: "/"},
        {label: "News and Events", url: "/news-and-events"},
    ]
    const categories = [
        {label: "All", url: "/news-and-events"},
        {label: "News", url: "/news-and-events/news"},
        {label: "Events", url: "/news-and-events/events"},
        {label: "Journals", url: "/news-and-events/journals"}
    ]
    const [autocomplete, setAutocomplete] = useState([])
    const handleInputChange = (newQuery) => {
        if(newQuery.length <= 0) {
            setAutocomplete([])
            return
        }
        const filteredData = data.allNewsEvents.nodes
            .filter(node => node.title.toLowerCase().includes(newQuery))
            .map(node => (
                {
                    ...node,
                    url: `/news-and-events/${node.slug}`
                }
            ))
        setAutocomplete(filteredData)
    }
    const getPathname = () => {
        switch (pageContext.type){
            case "*":
                return "/news-and-events"
            case "news":
                return "/news-and-events/news"
            case "event":
                return "/news-and-events/events"
            case "journal":
                return "/news-and-events/journals"
            default:
                return "/news-and-events"
        }
    }
    const { t } = useTranslation()
    return(
        <Layout pathname="/news-and-events">
            <PageBanner title={t("News and Events")} links={links} bannerImage={data.banner.gatsbyImageData}/>
            <section className="container grid grid-cols-1 lg:grid-cols-3 gap-y-12 lg:gap-12 py-12 md:py-16">
                <NewsEventList 
                    newsEvents={data.newsEvents.nodes}
                    currentPage={pageContext.currentPage}
                    totalCount={pageContext.totalCount}
                    pathname={getPathname()}
                />
                <div className="flex flex-col gap-8 row-start-1 lg:row-auto self-start lg:sticky lg:top-28">
                    <SearchBar
                        placeholder={"Search news and events..."}
                        autocomplete={autocomplete}
                        handleInputChange={handleInputChange}
                    />
                    <CategoryList categories={categories} activeCategory={location.pathname}/>
                </div>
            </section>
        </Layout>
    )
}

export default NewsEventPage

export const Head = ({ pageContext }) => {
    return (
      <SEO
        title={'ReGeniC | News and Event'}
        description={
            'Stay up-to-date with the latest news and events from ReGeniC. Read about our groundbreaking research, product launches, industry collaborations, and contributions to global healthcare.'
        }
        noIndex={pageContext.noIndex}
      />
    )
}

export const query = graphql`
  query ($language: String!, $locale: String, $type: String!, $skip: Int!, $limit: Int!) {
    locales: allLocale(filter: {ns: {in: ["common", "news-and-events"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    newsEvents: allContentfulNewsEvent(
        skip: $skip
        limit: $limit
        filter: { fieldType: { glob: $type }, node_locale: { glob: $locale } }
        sort: {order: DESC, fields: publishDate}
    ) {
        nodes {
            id
            image {
                gatsbyImageData(width: 1600, height: 900)
            }
            slug
            title
        }
    }
    allNewsEvents: allContentfulNewsEvent(
        filter: { node_locale: { glob: $locale } }
    ) {
        nodes {
            slug
            title
        }
    }
    banner: contentfulAsset(title: {eq: "News Event Banner"}) {
        gatsbyImageData
    }
  }
`